import React from "react"
import Layout from "../components/common/Layout"
import styles from "../css/services.module.css"

const ServiceSection = ({
    num = "",
    title1 = "",
    title2 = "",
    listTitle = "",
    children,
}) => {
    return (
        <div className={styles.section}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <p className={styles.num}>
                            <span>{num}</span>
                        </p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-7 mb-5">
                        <h4 className={styles.title}>
                            {title1}
                            <br />+<br />
                            {title2}
                        </h4>
                    </div>
                    <div className="col-md-5 mb-5">
                        <h3 className={styles.stepsTitle}>{listTitle}</h3>
                        <ul className={styles.steps}>{children}</ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Services = () => {
    return (
        <Layout>
            <ServiceSection
                num="01"
                title1="Bid"
                title2="Retarget"
                listTitle="RTB"
            >
                <li>
                    <span>Bid Request</span>
                </li>
                <li>
                    <span>User Information Delivery</span>
                </li>
                <li>
                    <span>Auctioning</span>
                </li>
                <li>
                    <span>Bid Assessment</span>
                </li>
                <li>
                    <span>Bid Placement</span>
                </li>
                <li>
                    <span>Auction Attribution</span>
                </li>
            </ServiceSection>
            <ServiceSection
                num="02"
                title1="Acquire"
                title2="Display"
                listTitle="Programmatic Direct"
            >
                <li>
                    <span>Demographic targeting</span>
                </li>
                <li>
                    <span>Content targeting</span>
                </li>
                <li>
                    <span>Device targeting</span>
                </li>
                <li>
                    <span>Geolocation targeting</span>
                </li>
                <li>
                    <span>Audience targeting</span>
                </li>
                <li>
                    <span>Micro Moment targeting</span>
                </li>
                <li>
                    <span>Retargeting</span>
                </li>
            </ServiceSection>
            <ServiceSection
                num="03"
                title1="Ideate"
                title2="Create"
                listTitle="Custom Creative"
            >
                <li>
                    <span>Display ad creative</span>
                </li>
                <li>
                    <span>Video creative</span>
                </li>
                <li>
                    <span>TV Creatives</span>
                </li>
                <li>
                    <span>Audio Creatives</span>
                </li>
            </ServiceSection>
            <ServiceSection
                num="04"
                title1="Develop"
                title2="Deploy"
                listTitle="Full Stack Development"
            >
                <li>
                    <span>Display ad creative</span>
                </li>
                <li>
                    <span>Video creative</span>
                </li>
                <li>
                    <span>TV Creatives</span>
                </li>
                <li>
                    <span>Audio Creatives</span>
                </li>
            </ServiceSection>
            <ServiceSection
                num="05"
                title1="Research"
                title2="Resolve"
                listTitle="Audience Insights"
            >
                <li>
                    <span>Demographic Overview</span>
                </li>
                <li>
                    <span>Location &amp; Language overview</span>
                </li>
                <li>
                    <span>Usage overview</span>
                </li>
                <li>
                    <span>Activity &amp; Behaviour</span>
                </li>
            </ServiceSection>
            <ServiceSection
                num="06"
                title1="Consolidate"
                title2="Improve"
                listTitle="Reporting &amp; Analytics"
            >
                <li>
                    <span>Ad Impact studies</span>
                </li>
                <li>
                    <span>Brand lift</span>
                </li>
                <li>
                    <span>Performance overview</span>
                </li>
                <li>
                    <span>Re Strategy</span>
                </li>
                <li>
                    <span>Viewability Measurement</span>
                </li>
                <li>
                    <span>Traffic Quality Analysis</span>
                </li>
            </ServiceSection>
        </Layout>
    )
}

export default Services
